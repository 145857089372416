:root {
  /* light theme */
  --switch-color: #00e0bb;
  --main-bg-color: #ffffff;
  --switch-shade-color: #00b99a;
  --footer-color: #58e0ca56;
  --subtitle-color: #008871;
  /* dark theme */
  --d-switch-color: #e08600;
  --d-font-color: #ffffff;
  --d-main-bg-color: #001b27;
  --d-switch-shade-color: #b36b00;
  --d-subtitle-color: #ff9900;
  /* border */
  --radius: 15px;
}
body {
  margin: 0;
  font-size: 16px;
  font-family: sofia-pro, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}

h1,
h2,
h3 {
  font-weight: 300;
}

p,
li {
  font-size: 1rem;
  font-weight: 300;
}

ul {
  padding: 0;
}

.contact-link {
  text-decoration: none;
  color: inherit;
  padding: 1px;
  border-bottom: 2px solid var(--switch-color);
}

a.contact-link:hover {
  transition: 0.3s;
  box-shadow: inset 0 -2rem 0 0 var(--switch-color);
  color: white;
}

li {
  display: block;
}

header {
  padding: 20vh 10px 100px 10px;
  box-sizing: border-box;
  height: 100vh;
}
section {
  padding: 20px 0;
  box-sizing: border-box;
}
footer {
  background-color: var(--footer-color);
  padding: 20px 30px;
}

.greeting {
  position: relative;
  height: 10vh;
}

.flip {
  position: absolute;
  z-index: 10;
}

.get-in-touch {
  margin: 1rem 0;
}

.skills-ul {
  padding: 0 0 0 20px;
  min-width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skills-li {
  margin-bottom: 5px;
}

.section-content {
  padding-top: 2rem;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-section {
  border: 1px solid var(--subtitle-color);
  border-radius: var(--radius);
  padding: 15px 10px;
  position: relative;
  margin-bottom: 30px;
}

.sub-section h3 {
  z-index: 10;
  margin: 0;
  position: absolute;
  background-color: var(--main-bg-color);
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--subtitle-color);
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 0 10px;
}

h3.width-180px {
  width: 180px;
}

h3.width-150px {
  width: 150px;
}

h3.width-60px {
  width: 60px;
}

.container {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
}
.section {
  margin-top: 3rem;
}
.bold {
  font-weight: 600;
}
.section-title {
  color: var(--subtitle-color);
  font-weight: 500;
}

.bubbling-container {
  margin: 5px;
}

/* SKILLS ANIMATION */
.bubbling-icons {
  box-shadow: inset 2px 2px 5px -1px grey;
  border-radius: 60px;
  width: 70px;
  height: 70px;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1);
  background-size: 70px 210px;
}

.languages {
  animation-name: bubbling;
  background-image: url('./resources/skills/language-icons.svg');
}
.tools {
  animation-name: bubbling;
  animation-delay: 1s;
  background-image: url('./resources/skills/tools-icons.svg');
}
.adobe {
  animation-name: bubbling;
  animation-delay: 2s;
  background-image: url('./resources/skills/adobe-icons.svg');
}

.flex-wide {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
@keyframes bubbling {
  0% {
    background-position: 0 0;
  }
  5% {
    background-position: 0 70px;
  }
  35% {
    background-position: 0 70px;
  }
  40% {
    background-position: 0 140px;
  }
  68% {
    background-position: 0 140px;
  }
  73% {
    background-position: 0 210px;
  }
  100% {
    background-position: 0 210px;
  }
}
/* Projects */
.projects-sub-section {
  margin: 1rem 0 100px 0;
  padding-top: 20px;
}
h3.firstline-title {
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 0 10px;
}
.project-images {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-self: center;
}

.project-images a {
  display: block;
  align-self: center;
  align-self: flex-end;
}
.desktop {
  width: 100%;
  max-width: 300px;
}
.mobile {
  width: 100%;
  margin-top: 10%;
  max-width: 100px;
}
.mobile-large {
  width: 100%;
  margin-top: 0;
  max-width: 150px;
}
.footer {
  text-align: center;
}
.contact-list li {
  display: inline-block;
  margin: 0 1rem;
}

/* MEDIA QUERIES */
@media (min-width: 360px) {
  h1 {
    font-size: 1.7rem;
  }
  h2,
  h3 {
    font-weight: 300;
    font-size: 1.4rem;
  }
  .intro {
    max-width: 450px;
  }
  .container {
    width: 85%;
  }
}
@media (min-height: 660px) {
  .intro,
  .get-in-touch {
    margin-top: 2rem;
  }
  .section {
    margin-top: 5rem;
  }
}
@media (min-width: 600px) {
  h1 {
    font-size: 2rem;
  }
  h2,
  h3 {
    font-weight: 300;
    font-size: 1.7rem;
  }
  p,
  li {
    font-size: 1.3rem;
  }
  .skills-section {
    margin: 15px 5px;
  }
}
@media (min-width: 966px) {
  .section-title {
    min-width: 200px;
    margin-top: 0;
  }

  .flex-wide {
    justify-content: space-between;
  }
  .section-content p {
    margin-top: 0.7rem;
  }

  .skills-section {
    max-width: 500px;
    margin: 15px 0;
  }
  .section-content {
    width: 100%;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2,
  h3 {
    font-weight: 300;
    font-size: 1.7rem;
  }
  .intro {
    max-width: 700px;
  }
  .project-images {
    min-width: 400px;
  }
  .projects-sub-section {
    display: flex;
    align-content: center;
  }
  .project-description {
    padding-left: 1rem;
  }
}
@media (min-width: 1182px) {
  body {
    line-height: 2.5rem;
  }
  .flex {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  .sub-section h3 {
    top: -23px;
  }
}

/* COLOR THEME CLASSES */

.App {
  /* width: 100%; */
  background-color: var(--main-bg-color);
  transition: background-color 0.6s;
}
a.darkLink {
  border-bottom: 2px solid var(--d-switch-color);
}
a.darkLink:hover {
  transition: 0.3s;
  box-shadow: inset 0 -2rem 0 0 var(--d-switch-color);
  color: var(--d-main-bg-color);
}
#darkBg {
  background-color: var(--d-main-bg-color);
  color: var(--d-font-color);
  transition: background-color 0.6s;
}
#darkTitle {
  color: var(--d-subtitle-color);
  background-color: var(--d-main-bg-color);
}

#darkBorder {
  border-color: var(--d-font-color);
}

#darkText {
  color: var(--d-font-color);
}
/* SLIDER */
.switch-icons {
  height: 20px;
  padding-top: 2px;
  width: 20px;
  box-sizing: border-box;
}
.moon {
  padding-top: 3px;
}
.switch-container {
  position: absolute;
  display: flex;
  top: 30px;
  right: 30px;
  line-height: initial;
}
/* Container around the slider */
.switch {
  position: relative;
  margin: 0 10px;
  display: inline-block;
  width: 47px;
  height: 25px;
}

/* Hide default HTML checkbox */
input,
input:focus,
.slider {
  outline: none;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.no_highlights {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--switch-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 12px;
  box-shadow: inset 1px 1px 3px 2px var(--switch-shade-color);
}

.slider:before {
  position: absolute;
  border-radius: 50%;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: var(--main-bg-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--d-switch-color);
  box-shadow: inset 1px 1px 3px 2px var(--d-switch-shade-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
